import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { getAllCenter, centerChangeStatusAction, insertUpdateCenterAction, getCenterAction } from "../actions/centerActions";
const INITIAL_STATE = {
    centers: {
        data: {
            records: [],
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCenter: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    changeStatusDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

}

const centerReducer = createSlice({
    name: "centerData",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCenterDetailsState(state, action) {
            state.selectedCenter = INITIAL_STATE.selectedCenter;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },


    },
    extraReducers: (builder) => {

        //  --------getAllCenter start--------------------

        builder.addCase(getAllCenter.fulfilled, (state, action) => {

            state.centers.data.records = action.payload.data;
            state.centers.apiMsg.status = action.meta.requestStatus;
            state.centers.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAllCenter.pending, (state, action) => {

            state.centers.data.records = null;
            state.centers.apiMsg.message = action.meta.requestStatus;;
            state.centers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllCenter.rejected, (state, action) => {

            state.centers.apiMsg.message = action.error.message;
            state.centers.apiMsg.status = action.meta.requestStatus;
        });

        //  -------- getAllCenter Action end--------------------

        //   getCenter start

        builder.addCase(getCenterAction.fulfilled, (state, action) => {
            state.selectedCenter.data = action.payload.data
            state.selectedCenter.apiMsg.status = action.meta.requestStatus;
            state.selectedCenter.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCenterAction.pending, (state, action) => {
            state.selectedCenter.data = null;
            state.selectedCenter.apiMsg.message = action.meta.requestStatus;;
            state.selectedCenter.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCenterAction.rejected, (state, action) => {
            state.selectedCenter.apiMsg.message = action.error.message;
            state.selectedCenter.apiMsg.status = action.meta.requestStatus;
        });


        //   getCenter end



        // insertUpdateCenterAction start

        builder.addCase(
            insertUpdateCenterAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;



                if (action.payload.newRecord) {


                    if (state.centers.data.records.length) {
                        state.centers.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.centers.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "client-error"
                });

            }
        );
        builder.addCase(
            insertUpdateCenterAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCenterAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        //   insert update center action end

        //------------------------------- changeStatusAction start -------------------------

        builder.addCase(
            centerChangeStatusAction.fulfilled,
            (state, action) => {
                console.log("changeStatusAction", action);

                state.changeStatusDetails.data = action.payload.data;
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;
                state.changeStatusDetails.apiMsg.message = action.payload.message.message;

                let updatedRecord = state.centers.data.records.map(x => {
                    if (x.id == action.meta.arg.id) {
                        x.isActive = state.changeStatusDetails.data.isActive;
                        return x;
                    }
                    else {
                        return x;
                    }
                })



                state.centers.data.records = updatedRecord;

                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "success"
                });

            }
        );
        builder.addCase(
            centerChangeStatusAction.pending,
            (state, action) => {
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            centerChangeStatusAction.rejected,
            (state, action) => {
                state.changeStatusDetails.apiMsg.message = action.error.message;
                state.changeStatusDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "error"
                });
            }
        );




    },
});
export const { RESET, ResetCenterDetailsState } = centerReducer.actions;
export default centerReducer.reducer;