import axios from 'axios';

var headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
}

const APIURL = "http://localhost:8000";

export const apiCall = async (path, method, data, sizesCheck) => {
    if (method === 'POST') {
        headers["Content-Type"] = 'application/json';
        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,
            data: data
        }).then(response => {
            if (path === 'admin/login') {
                if (!response.data.hasError) {
                    axios.defaults.headers = {
                        Authorization: `Bearer ${response.data.data.tokenDetails.token}`,
                        TenantId: response.data.data.user.tenantId,
                        businessId: null
                    };

                }

            }
            //console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }
    else {

        var res = await axios({
            method: method,
            url: sizesCheck ? path : `${APIURL}${path}`,
            headers,

        }).then(response => {

            //console.log("API Response", response)
            return response;

        }).catch(error => {
            console.log("API Error", error)
            return error.response;
        })
    }

    return res;
}

