

const apiUrls = {
    clients: {
        getAll: "/clients/getall",
        getClient: "/client/get",
        insertupdate: "/client/insertupdate",
        changeStatus: '/client/changestatus'
    },
    location: {
        getAll: "/locations/getall",
        getLocation: "/location/get",
        insertupdate: "/location/insertupdate",
        changeStatus: '/location/changestatus'
    },
    center: {
        getAll: "/center/getall",
        getCenter: "/center/get",
        insertupdate: "/center/insertupdate",
        changeStatus: '/center/changestatus'
    }
};

export default apiUrls;
