import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

import WithLoading from './components/shared/WithLoading';
import RouteLoader from './components/shared/RouteLoader';
import Layout from './components/Layout';

function AppRoutes() {
    const LoginPage = WithLoading(React.lazy(() => import("./pages/Login/Login")));
    const AffiliateSignInPage = WithLoading(React.lazy(() => import("./pages/Login/AffiliateSignIn")));
    const MySamplesPage = WithLoading(React.lazy(() => import("./pages/MySamples/MySamples")));
    const MySamplesDocumentsPage = WithLoading(React.lazy(() => import("./pages/MySamples/MySamplesDocuments")));

    const DashboardPage = WithLoading(React.lazy(() => import("./pages/Dashboard/Dashboard")));

    const ClientsPage = WithLoading(React.lazy(() => import("./pages/Clients/Clients")));
    const ClientsAddPage = WithLoading(React.lazy(() => import("./pages/Clients/ClientsForm")));


    const CenterPage = WithLoading(React.lazy(() => import("./pages/Center/Center")));
    const CenterAddPage = WithLoading(React.lazy(() => import("./pages/Center/CenterForm")));

    const PatientPage = WithLoading(React.lazy(() => import("./pages/Patients/Patients")));
    const PatientAddPage = WithLoading(React.lazy(() => import("./pages/Patients/PatientsForm")));

    const LocationPage = WithLoading(React.lazy(() => import("./pages/Location/Location")));
    const LocationAddPage = WithLoading(React.lazy(() => import("./pages/Location/LocationForm")));

    const SampleCollectionPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleCollection/SampleCollection")));
    const SampleCollectionAddPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleCollection/SampleCollectionForm")));
    const SampleConfirmationPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/SampleConfirmation/SampleConfirmation")));
    const UpdateStatusPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/UpdateStatus/UpdateStatus")));
    const LandingScreenPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreen")));
    const LandingScreenEditPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreenForm")));
    const LandingScreenDocumentsPage = WithLoading(React.lazy(() => import("./pages/SampleMaster/LandingScreen/LandingScreenDocuments")));
    const CaneSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Cane/Cane")));
    const CaneSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/Cane/CaneAddForm")));
    const CaneSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/Cane/CaneEditForm")));
    const CaneSettingCopyPage = WithLoading(React.lazy(() => import("./pages/Settings/Cane/CaneCopyForm")));
    const TankSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Tank/Tank")));
    const TankSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/Tank/TankAddForm")));
    const TankSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/Tank/TankEditForm")));
    const TankSettingCopyPage = WithLoading(React.lazy(() => import("./pages/Settings/Tank/TankCopyForm")));
    const CannisterSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Cannister/Cannister")));
    const CannisterSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/Cannister/CannisterAddForm")));
    const CannisterSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/Cannister/CannisterEditForm")));
    const CannisterSettingCopyPage = WithLoading(React.lazy(() => import("./pages/Settings/Cannister/CannisterCopyForm")));
    const PatientLanguageSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/PatientLanguage/PatientLanguage")));
    const PatientLanguageSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/PatientLanguage/PatientLanguageAddForm")));
    const PatientLanguageSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/PatientLanguage/PatientLanguageEditForm")));
    const SpecimenStatusSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/SpecimenStatus/SpecimenStatus")));
    const SpecimenStatusSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/SpecimenStatus/SpecimenStatusAddForm")));
    const SpecimenStatusSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/SpecimenStatus/SpecimenStatusEditForm")));
    const AdminSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/AdminUser/AdminUser")));
    const AdminSettingAddPage = WithLoading(React.lazy(() => import("./pages/Settings/AdminUser/AdminUsersAddForm")));
    const AdminSettingEditPage = WithLoading(React.lazy(() => import("./pages/Settings/AdminUser/AdminUsersEditForm")));
    const RolesSettingPage = WithLoading(React.lazy(() => import("./pages/Settings/Roles")));
    const LookUpConfigurationPage = WithLoading(React.lazy(() => import("./pages/Configuration/LookUpMaster")));
    const EmbryoPage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Embryo/Embryo")));
    const OoctyePage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Oocyte/Ooctye")));
    const SpermPage = WithLoading(React.lazy(() => import("./pages/InventoryManagement/Sperm/Sperm")));


const PgtResultPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResult")));
const PgtResultAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResultAddForm")));
const PgtResultEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTResult/PGTResultEditForm")));
const EmbryoStatusPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatus")));
const EmbryoStatusAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatusAddForm")));
const EmbryoStatusEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EmbryoStatus/EmbryoStatusEditForm")));
const EggSourcePage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EGGSource/EGGSource")));
const EggSourceAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EGGSource/EGGSourceAddForm")));
const EggSourceEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/EGGSource/EGGSourceEditForm")));
const SpermSourcePage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/SpermSource/SpermSource")));
const SpermSourceAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/SpermSource/SpermSourceAddForm")));
const SpermSourceEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/SpermSource/SpermSourceEditForm")));
const FreezeMethodPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/FreezeMethod/FreezeMethod")));
const FreezeMethodAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/FreezeMethod/FreezeMethodAddForm")));
const FreezeMethodEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/FreezeMethod/FreezeMethodEditForm")));
const DeviceTypePage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/DeviceType/DeviceType")));
const DeviceTypeAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/DeviceType/DeviceTypeAddForm")));
const DeviceTypeEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/DeviceType/DeviceTypeEditForm")));
const PGTLabPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTLab/PGTLab")));
const PGTLabAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTLab/PGTLabAddForm")));
const PGTLabEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/PGTLab/PGTLabEditForm")));
const CryoTechPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/CryoTech/CryoTech")));
const CryoTechAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/CryoTech/CryoTechAddForm")));
const CryoTechEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/CryoTech/CryoTechEditForm")));
const BXTechPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/BXTech/BXTech")));
const BXTechAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/BXTech/BXTechAddForm")));
const BXTechEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/BXTech/BXTechEditForm")));
const TubingTechPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/TubingTech/TubingTech")));
const TubingTechAddPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/TubingTech/TubingTechAddForm")));
const TubingTechEditPage =  WithLoading(React.lazy(() => import("./pages/EmbryoSetup/TubingTech/TubingTechEditform")));
const OocyteStagePage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteStage/OocyteStage")));
const OocyteStageAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteStage/OocyteStageAddForm")));
const OocyteStageEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteStage/OocyteStageEditForm")));
const OocyteDayPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteDay/OocyteDay")));
const OocyteDayAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteDay/OocyteDayAddForm")));
const OocyteDayEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/OocyteDay/OocyteDayEditForm")));
const OocyteEggSourcePage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/EggSource/EggSource")));
const OocyteEggSourceAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/EggSource/EggSourceAddForm")));
const OocyteEggSourceEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/EggSource/EggSourceEditForm")));
const OocyteFreezeMethodPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/FreezeMethod/FreezeMethod")));
const OocyteFreezeMethodAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/FreezeMethod/FreezeMethodAddForm")));
const OocyteFreezeMethodEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/FreezeMethod/FreezeMethodEditForm")));
const OocyteDeviceTypePage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/DeviceType/DeviceType")));
const OocyteDeviceTypeAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/DeviceType/DeviceTypeAddForm")));
const OocyteDeviceTypeEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/DeviceType/DeviceTypeEditForm")));
const OocyteCaneLabelPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CaneLabel/CaneLabel")));
const OocyteCaneLabelAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CaneLabel/CaneLabelAddForm")));
const OocyteCaneLabelEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CaneLabel/CaneLabelEditForm")));
const OocyteCryoTechPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CryoTech/CryoTech")));
const OocyteCryoTechAddPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CryoTech/CryoTechAddForm")));
const OocyteCryoTechEditPage =  WithLoading(React.lazy(() => import("./pages/OocyteSetup/CryoTech/CryoTechEditForm")));
const SpermSampleTypePage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SampleType/SampleType")));
const SpermSampleTypeAddPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SampleType/SampleTypeAddForm")));
const SpermSampleTypeEditPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SampleType/SampleTypeEditForm")));
const SpermSpermSourcePage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SpermSource/SpermSource")));
const SpermSpermSourceAddPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SpermSource/SpermSourceAddForm")));
const SpermSpermSourceEditPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/SpermSource/SpermSourceEditForm")));
const SpermFreezeMethodPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/FreezeMethod/FreezeMethod")));
const SpermFreezeMethodAddPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/FreezeMethod/FreezeMethodAddForm")));
const SpermFreezeMethodEditPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/FreezeMethod/FreezeMethodEditForm")));
const SpermVialTypePage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/VialType/VialType")));
const SpermVialTypeAddPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/VialType/VialTypeAddForm")));
const SpermVialTypeEditPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/VialType/VialTypeEditForm")));
const SpermCryoTechPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/CryoTech/CryoTech")));
const SpermCryoTechAddPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/CryoTech/CryoTechAddForm")));
const SpermCryoTechEditPage =  WithLoading(React.lazy(() => import("./pages/SpermSetup/CryoTech/CryoTechEditForm")));
const AuditLogReportPage =  WithLoading(React.lazy(() => import("./pages/Reports/AuditLogReport/AuditLogReport")));
const InventoryReportPage =  WithLoading(React.lazy(() => import("./pages/Reports/Inventory/Inventory")));
const ManageShipmentPage =  WithLoading(React.lazy(() => import("./pages/ManageShipment/ManageShipment")));
const ManageShipmentGenerateQRCodePage =  WithLoading(React.lazy(() => import("./pages/ManageShipment/GenerateQrCode")));
const LongTermFacilityPage =  WithLoading(React.lazy(() => import("./pages/LongTermFacility/LongTermFacility")));
const LongTermFacilityAddPage =  WithLoading(React.lazy(() => import("./pages/LongTermFacility/LongTermFacilityAddForm")));
const LongTermFacilityEditPage =  WithLoading(React.lazy(() => import("./pages/LongTermFacility/LongTermFacilityEditForm")));

    return (
        <div>

            <Routes>
                <Route path='' element={<LoginPage />} />
                <Route path="/" >
                    <Route path='login' element={<LoginPage />} />
                    <Route path='login/affiliatesignin' element={<AffiliateSignInPage />} />

                </Route>
                <Route path="/" element={<Layout />} >
                    <Route path='dashboard' element={<DashboardPage />} />

                    <Route path='mysamples' element={<MySamplesPage />} />
                    <Route path='mysamples/documents' element={<MySamplesDocumentsPage />} />

                    <Route path='clients' element={<ClientsPage />} />
                    <Route path='clients/add' element={<ClientsAddPage />} />


                    <Route path='center' element={<CenterPage />} />

                    <Route path='center/add' element={<CenterAddPage />} />

                    <Route path='patient' element={<PatientPage />} />

                    <Route path='patient/add' element={<PatientAddPage />} />

                    <Route path='location' element={<LocationPage />} />

                    <Route path='location/add' element={<LocationAddPage />} />
                    <Route path='samplemaster'>
                        <Route path='samplecollection' element={<SampleCollectionPage />} />
                        <Route path='samplecollection/add' element={<SampleCollectionAddPage />} />
                        <Route path='sampleconfirmation' element={<SampleConfirmationPage />} />
                        <Route path='landingscreen' element={<LandingScreenPage />} />
                        <Route path='landingscreen/edit' element={<LandingScreenEditPage />} />
                        <Route path='landingscreen/documents' element={<LandingScreenDocumentsPage />} />
                        <Route path='updatestatus' element={<UpdateStatusPage />} />

                    </Route>
                    <Route path="/settings">
                        <Route path='cane'>
                            <Route index element={<CaneSettingPage />} />
                            <Route path='add' element={<CaneSettingAddPage />} />
                            <Route path='edit' element={<CaneSettingEditPage />} />
                            <Route path='copy' element={<CaneSettingCopyPage />} />
                        </Route>

                        <Route path='tank'>
                            <Route index element={<TankSettingPage />} />
                            <Route path='add' element={<TankSettingAddPage />} />
                            <Route path='edit' element={<TankSettingEditPage />} />
                            <Route path='copy' element={<TankSettingCopyPage />} />
                        </Route>

                        <Route path='cannister'>
                            <Route index element={<CannisterSettingPage />} />
                            <Route path='add' element={<CannisterSettingAddPage />} />
                            <Route path='edit' element={<CannisterSettingEditPage />} />
                            <Route path='copy' element={<CannisterSettingCopyPage />} />
                        </Route>

                        <Route path='patientlanguage'>
                            <Route index element={<PatientLanguageSettingPage />} />
                            <Route path='add' element={<PatientLanguageSettingAddPage />} />
                            <Route path='edit' element={<PatientLanguageSettingEditPage />} />
                        </Route>

                        <Route path='specimentypestatus'>
                            <Route index element={<SpecimenStatusSettingPage />} />
                            <Route path='add' element={<SpecimenStatusSettingAddPage />} />
                            <Route path='edit' element={<SpecimenStatusSettingEditPage />} />
                        </Route>
                        <Route path='user'>
                        <Route index element={<AdminSettingPage />} />
                            <Route path='add' element={<AdminSettingAddPage />} />
                            <Route path='edit' element={<AdminSettingEditPage />} />
                        </Route>

                        <Route path='roles' element={<RolesSettingPage />} />
                    </Route>

                    <Route path="/inventorymanagement">
                        <Route path='embryo'>
                            <Route index element={<EmbryoPage />} />
                        </Route>
                        <Route path='ooctye'>
                            <Route index element={<OoctyePage />} />
                        </Route>
                        <Route path='sperm'>
                            <Route index element={<SpermPage />} />
                        </Route>
                    </Route>

                    <Route path='longtermfacility' element={<LongTermFacilityPage />} />
                    <Route path='longtermfacility/add' element={<LongTermFacilityAddPage />} />
                    <Route path='longtermfacility/edit' element={<LongTermFacilityEditPage />} />

                    <Route path='manageshipment' element={<ManageShipmentPage />} />
                    <Route path='manageshipment/settings' element={<ManageShipmentGenerateQRCodePage />} />

                    <Route path="/embryosetup">
                        <Route path='pgtresult'>
                            <Route index element={<PgtResultPage />} />
                            <Route path='add' element={<PgtResultAddPage />} />
                            <Route path='edit' element={<PgtResultEditPage />} />
                        </Route>
                        {/* <Route path='embryostatus'>
                            <Route index element={<EmbryoStatusPage />} />
                            <Route path='add' element={<EmbryoStatusAddPage />} />
                            <Route path='edit' element={<EmbryoStatusEditPage />} />
                        </Route> */}
                        <Route path='eggsource'>
                            <Route index element={<EggSourcePage />} />
                            <Route path='add' element={<EggSourceAddPage />} />
                            <Route path='edit' element={<EggSourceEditPage />} />
                        </Route>
                        <Route path='spermsource'>
                            <Route index element={<SpermSourcePage />} />
                            <Route path='add' element={<SpermSourceAddPage />} />
                            <Route path='edit' element={<SpermSourceEditPage />} />
                        </Route>
                        <Route path='freezemethod'>
                            <Route index element={<FreezeMethodPage />} />
                            <Route path='add' element={<FreezeMethodAddPage />} />
                            <Route path='edit' element={<FreezeMethodEditPage />} />
                        </Route>
                        <Route path='devicetype'>
                            <Route index element={<DeviceTypePage />} />
                            <Route path='add' element={<DeviceTypeAddPage />} />
                            <Route path='edit' element={<DeviceTypeEditPage />} />
                        </Route>
                        <Route path='pgtlab'>
                            <Route index element={<PGTLabPage />} />
                            <Route path='add' element={<PGTLabAddPage />} />
                            <Route path='edit' element={<PGTLabEditPage />} />
                        </Route>
                        <Route path='cryotech'>
                            <Route index element={<CryoTechPage />} />
                            <Route path='add' element={<CryoTechAddPage />} />
                            <Route path='edit' element={<CryoTechEditPage />} />
                        </Route>
                        <Route path='bxtech'>
                            <Route index element={<BXTechPage />} />
                            <Route path='add' element={<BXTechAddPage />} />
                            <Route path='edit' element={<BXTechEditPage />} />
                        </Route>
                        <Route path='tubingtech'>
                            <Route index element={<TubingTechPage />} />
                            <Route path='add' element={<TubingTechAddPage />} />
                            <Route path='edit' element={<TubingTechEditPage />} />
                        </Route>
                    </Route>

                    <Route path="/oocytesetup">
                        <Route path='oocytestage'>
                            <Route index element={<OocyteStagePage />} />
                            <Route path='add' element={<OocyteStageAddPage />} />
                            <Route path='edit' element={<OocyteStageEditPage />} />
                        </Route>
                        <Route path='oocyteday'>
                            <Route index element={<OocyteDayPage />} />
                            <Route path='add' element={<OocyteDayAddPage />} />
                            <Route path='edit' element={<OocyteDayEditPage />} />
                        </Route>
                        <Route path='eggsource'>
                            <Route index element={<OocyteEggSourcePage />} />
                            <Route path='add' element={<OocyteEggSourceAddPage />} />
                            <Route path='edit' element={<OocyteEggSourceEditPage />} />
                        </Route>
                        <Route path='freezemethod'>
                            <Route index element={<OocyteFreezeMethodPage />} />
                            <Route path='add' element={<OocyteFreezeMethodAddPage />} />
                            <Route path='edit' element={<OocyteFreezeMethodEditPage />} />
                        </Route>
                        <Route path='devicetype'>
                            <Route index element={<OocyteDeviceTypePage />} />
                            <Route path='add' element={<OocyteDeviceTypeAddPage />} />
                            <Route path='edit' element={<OocyteDeviceTypeEditPage />} />
                        </Route>
                        <Route path='canelabel'>
                            <Route index element={<OocyteCaneLabelPage />} />
                            <Route path='add' element={<OocyteCaneLabelAddPage />} />
                            <Route path='edit' element={<OocyteCaneLabelEditPage />} />
                        </Route>
                        <Route path='cryotech'>
                            <Route index element={<OocyteCryoTechPage />} />
                            <Route path='add' element={<OocyteCryoTechAddPage />} />
                            <Route path='edit' element={<OocyteCryoTechEditPage />} />
                        </Route>                        
                    </Route>

                    <Route path="/spermsetup">
                    <Route path='sampletype'>
                            <Route index element={<SpermSampleTypePage />} />
                            <Route path='add' element={<SpermSampleTypeAddPage />} />
                            <Route path='edit' element={<SpermSampleTypeEditPage />} />
                        </Route>  
                        <Route path='spermsource'>
                            <Route index element={<SpermSpermSourcePage />} />
                            <Route path='add' element={<SpermSpermSourceAddPage />} />
                            <Route path='edit' element={<SpermSpermSourceEditPage />} />
                        </Route>
                        <Route path='freezemethod'>
                            <Route index element={<SpermFreezeMethodPage />} />
                            <Route path='add' element={<SpermFreezeMethodAddPage />} />
                            <Route path='edit' element={<SpermFreezeMethodEditPage />} />
                        </Route>
                        <Route path='vialtype'>
                            <Route index element={<SpermVialTypePage />} />
                            <Route path='add' element={<SpermVialTypeAddPage />} />
                            <Route path='edit' element={<SpermVialTypeEditPage />} />
                        </Route>
                        <Route path='cryotech'>
                            <Route index element={<SpermCryoTechPage />} />
                            <Route path='add' element={<SpermCryoTechAddPage />} />
                            <Route path='edit' element={<SpermCryoTechEditPage />} />
                        </Route>
                    </Route>


                    <Route path="/reports">
                        <Route path='auditlogreport'>
                            <Route index element={<AuditLogReportPage />} />
                        </Route>
                        <Route path='inventory'>
                            <Route index element={<InventoryReportPage />} />
                        </Route>
                    </Route>

                    <Route path='configuration/lookupmaster' element={<LookUpConfigurationPage />} />
                </Route>
            </Routes>
        </div>
    )
}

export default AppRoutes