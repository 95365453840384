import { combineReducers } from 'redux'
import clientReducer from './clientReducer';
import centerReducer from './centerReducer';
import locationReducer from './locationReducer';
const reducer = {
    clientReducer,
    centerReducer,
    locationReducer
}

const appReducer = combineReducers(reducer);


const rootReducer = (state, action) => {
  console.log("state",state);
  console.log("action",action);
  return appReducer(state, action)
  
}

export default rootReducer;
// export default combineReducers(reducer);

export function CLEARSTORE() {
  return {
    type: "CLEARSTORE"
  };
}