import React from 'react'
import { Link } from "react-router-dom"

function Header() {


  return (
    <nav className="navbar navbar-expand-lg nav-header border-bottom border-body sw-navbar-h" data-bs-theme="dark">
      <div className="container-fluid">
        <a className="navbar-brand d-lg-none" type="button" id="header-sidemenu-toggle">Encompasscryo
          <i className="fa-solid fa-bars cursor-poiner ms-1" type="button" id='dc-sidemenu-togglebar-head'></i>
        </a>

        <ul className="navbar-nav ms-auto mb-lg-0">

          <li className="nav-item dropdown nav-acc-dropdown">
            <a className="nav-link dropdown-toggle p-0"  type='button' role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <div className="nav-profile-img float-start pe-2">
                <i className="fa-solid fa-user-tie profile-icon"></i>
                {/* <img src={FaceImg} alt="image" className="profile-img"/> */}
              </div>
              <span className="float-start">John Carter</span>
            </a>
            <ul className="dropdown-menu nav-acc-dropdown-menu">
              <li><a type="button" className="dropdown-item">Profile</a></li>
              <li><a type="button" className="dropdown-item" >Settings</a></li>
              <li><a type="button" className="dropdown-item">Change Password</a></li>
              <li>
                <hr className="dropdown-divider mb-0 mt-0" />
              </li>
              <li><Link className="dropdown-item" to={`/login`}><i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Sign
                Out</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Header