import { createAsyncThunk } from "@reduxjs/toolkit";
import apiUrls from "../../API/api-urls";
import { apiCall } from "../../API";

export const getAllLocations = createAsyncThunk(
    "Location/GetAllLocations",
    async (data) => {
        return apiCall(`${apiUrls.location.getAll}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getLocationAction = createAsyncThunk(
    "Location/GetLocation",
    async (data) => {
        return apiCall(`${apiUrls.location.getLocation}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateLocationAction = createAsyncThunk(
    "Location/insertupdateLocation",
    async (data) => {
        return apiCall(`${apiUrls.location.insertupdate}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const LocationChangeStatusAction = createAsyncThunk(
    "Location/changestatus",
    async (data) => {
        return apiCall(`${apiUrls.location.changeStatus}`, "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
