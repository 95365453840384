import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
function SideMenu() {

  const location = useLocation();


  useEffect(() => {

    var sidemenuToggleBarEle = document.getElementsByClassName("dc-sidemenu-togglebar")[0];
    var sidemenuToggleBarHeadEle = document.getElementById("dc-sidemenu-togglebar-head");

    sidemenuToggleBarHeadEle.addEventListener("click", function () {
      sidemenuToggleBarEle.click();
    });


  }, [])

  useEffect(() => {
    const menuLinks = document.querySelectorAll('.dc-submenu-link');
    menuLinks.forEach(link => {
      if (link.getAttribute('href') === window.location.pathname) {
        const parentDiv = link.closest('.dc-has-menu');
        if (parentDiv) {
          parentDiv.setAttribute('aria-expanded', 'true');
          parentDiv.classList.add('show');
        }
      }
    });
  }, []);


  useEffect(() => {
    const currentRoute = location.pathname;

    const routeToTitleMap = {
      '/login': 'Login',
      '/dashboard': 'Dashboard',
      '/location': 'Location',
      '/location/add': 'Location',
      '/center': 'Center',
      '/center/add': "Center",
      '/clients': 'Clients',
      '/clients/add': 'Clients',
      '/settings/user': "Admin Users",
      '/settings/user/add': "Admin Users",
      '/settings/user/edit': "Admin Users",
      '/settings/roles': "Settings",
      '/configuration/lookupmaster': "Configuration",
      '/patient': "Patients",
      '/patient/add': "Patients",
      '/samplemaster/samplecollection': "Sample Collection",
      '/samplemaster/samplecollection/add': "Sample Collection",
      '/samplemaster/sampleconfirmation': "Sample Confirmation",
      '/samplemaster/sampleconfirmation/add': "Sample Confirmation",
      '/samplemaster/landingscreen': "Landing Screen",
      '/samplemaster/landingscreen/edit': "Landing Screen",
      '/samplemaster/landingscreen/documents': "Landing Screen",
      '/settings/cane': "Cane",
      '/settings/cane/add': "Cane",
      '/settings/cane/edit': "Cane",
      '/settings/cane/copy': "Cane",
      '/settings/tank': "Tank",
      '/settings/tank/add': "Tank",
      '/settings/tank/edit': "Tank",
      '/settings/tank/copy': "Tank",
      '/settings/cannister': "Cannister",
      '/settings/cannister/add': "Cannister",
      '/settings/cannister/edit': "Cannister",
      '/settings/cannister/copy': "Cannister",
      '/settings/patientlanguage': "Patient Language",
      '/settings/patientlanguage/add': "Patient Language",
      '/settings/patientlanguage/edit': "Patient Language",
      '/inventorymanagement/embryo': "Embryo",
      '/inventorymanagement/ooctye': "Ooctye",
      '/inventorymanagement/sperm': "Sperm",
      '/embryosetup/pgtresult': 'PGT Result',
      '/embryosetup/pgtresult/add': 'PGT Result',
      '/embryosetup/pgtresult/edit': 'PGT Result',
      '/embryosetup/eggsource': 'EGG Source',
      '/embryosetup/eggsource/add': 'EGG Source',
      '/embryosetup/eggsource/edit': 'EGG Source',
      '/embryosetup/spermsource': 'Sperm Source',
      '/embryosetup/spermsource/add': 'Sperm Source',
      '/embryosetup/spermsource/edit': 'Sperm Source',
      '/embryosetup/freezemethod': 'Freeze Method',
      '/embryosetup/freezemethod/add': 'Freeze Method',
      '/embryosetup/freezemethod/edit': 'Freeze Method',
      '/embryosetup/devicetype': 'Device Type',
      '/embryosetup/devicetype/add': 'Device Type',
      '/embryosetup/devicetype/edit': 'Device Type',
      '/embryosetup/pgtlab': 'PGT Lab',
      '/embryosetup/pgtlab/add': 'PGT Lab',
      '/embryosetup/pgtlab/edit': 'PGT Lab',
      '/embryosetup/cryotech': 'Cryo Tech',
      '/embryosetup/cryotech/add': 'Cryo Tech',
      '/embryosetup/cryotech/edit': 'Cryo Tech',
      '/embryosetup/bxtech': 'BX Tech',
      '/embryosetup/bxtech/add': 'BX Tech',
      '/embryosetup/bxtech/edit': 'BX Tech',
      '/embryosetup/tubingtech': 'Tubing Tech',
      '/embryosetup/tubingtech/add': 'Tubing Tech',
      '/embryosetup/tubingtech/edit': 'Tubing Tech',
      "/oocytesetup/oocytestage": 'Oocyte Stage',
      "/oocytesetup/oocytestage/add": 'Oocyte Stage',
      "/oocytesetup/oocytestage/edit": 'Oocyte Stage',
      "/oocytesetup/oocyteday": 'Oocyte Day',
      "/oocytesetup/oocyteday/add": 'Oocyte Day',
      "/oocytesetup/oocyteday/edit": 'Oocyte Day',
      "/oocytesetup/eggsource": 'Egg Source',
      "/oocytesetup/eggsource/add": 'Egg Source',
      "/oocytesetup/eggsource/edit": 'Egg Source',
      "/oocytesetup/freezemethod": 'Freeze Method',
      "/oocytesetup/freezemethod/add": 'Freeze Method',
      "/oocytesetup/freezemethod/edit": 'Freeze Method',
      "/oocytesetup/devicetype": 'Device Type',
      "/oocytesetup/devicetype/add": 'Device Type',
      "/oocytesetup/devicetype/edit": 'Device Type',
      "/oocytesetup/canelabel": 'Cane Label',
      "/oocytesetup/canelabel/add": 'Cane Label',
      "/oocytesetup/canelabel/edit": 'Cane Label',
      "/oocytesetup/cryotech": 'Cryo Tech',
      "/oocytesetup/cryotech/add": 'Cryo Tech',
      "/oocytesetup/cryotech/edit": 'Cryo Tech',
      "/spermsetup/sampletype" : 'Sample Type',
      "/spermsetup/sampletype/add" : 'Sample Type',
      "/spermsetup/sampletype/edit" : 'Sample Type',
      "/spermsetup/spermsource" : 'Sperm Source',
      "/spermsetup/spermsource/add" : 'Sperm Source',
      "/spermsetup/spermsource/edit" : 'Sperm Source',
      "/spermsetup/freezemethod" : "Freeze Method",
      "/spermsetup/freezemethod/add" : "Freeze Method",
      "/spermsetup/freezemethod/edit" : "Freeze Method",
      "/spermsetup/vialtype" : "Vial Type",
      "/spermsetup/vialtype/add" : "Vial Type",
      "/spermsetup/vialtype/edit" : "Vial Type",
      "/spermsetup/cryotech" : "Cryo Tech",
      "/spermsetup/cryotech/add" : "Cryo Tech",
      "/spermsetup/cryotech/edit" : "Cryo Tech",
      '/reports/auditlogreport': 'Audit Log Report',
      '/reports/inventory': 'Inventory',
      '/settings/specimentypestatus': 'Specimen Type Status',
      '/settings/specimentypestatus/add': 'Specimen Type Status',
      '/settings/specimentypestatus/edit': 'Specimen Type Status',
      '/samplemaster/updatestatus': "Manage Inventory",
      '/samplemaster/updatestatus/add': "Manage Inventory",
      "/manageshipment": "Manage Shipment",
      "/manageshipment/generateqrcode": "Manage Shipment",
      "/longtermfacility" : "Long-Term Facility",
      "/longtermfacility/add" : "Long-Term Facility",
      "/longtermfacility/edit" : "Long-Term Facility",

    };

    document.title = `${routeToTitleMap[currentRoute] || "Encompasscryo"} `;
  }, [location.pathname]);




  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };


  function handleToggle() {
    var inventoryToggleEle = document.getElementsByClassName("dc-sidemenu-togglebar")[0];
    inventoryToggleEle.click();

  }
  useEffect(() => {

    const sidebarOffcanvasid = document.getElementById('sidebarOffcanvas');
    const isSidebarShown = sidebarOffcanvasid.classList.contains('show');

    if (location.pathname.startsWith("/inventorymanagement/")) {
      if (isSidebarShown) {
        setTimeout(handleToggle, 200);
      }
      else {
        return;
      }
    }
    else {
      if (!isSidebarShown) {
        setTimeout(handleToggle, 200);
      }
      else {
        return;
      }
    }
  }, [location.pathname]);



  return (

    <div className={`sidebar-menu offcanvas offcanvas-start show`} tabIndex="-1" id="sidebarOffcanvas"
      aria-labelledby="sidebarOffcanvasLabel" data-bs-scroll="true" data-bs-backdrop="false">
      <div>
        <div className="d-flex align-items-center lef-logo-part sw-navbar-h">
          <div className="admin-logo">
            <span className="admin-logo-name">Encompasscryo</span>
            <i className="fa-solid fa-bars cursor-poiner ms-1 dc-sidemenu-togglebar" type="button"
              data-bs-toggle="offcanvas" data-bs-target="#sidebarOffcanvas" aria-controls="sidebarOffcanvas"></i>
          </div>

        </div>
        <hr className="m-0" />
        <ul className="list-unstyled ps-0">
          <li>
            <Link to="/dashboard" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/dashboard')}`}>
              <i className="fa-solid fa-gauge smb-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/clients" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/clients')}`}>
              <i className="fa-solid fa-users smb-icon"></i>
              <span className="menu-title">Clients</span>
            </Link>
          </li>
          <li>
            <Link to="/center" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/center')}`}>
              <i className="fa-solid fa-house-chimney-medical smb-icon"></i>
              <span className="menu-title">Centers</span>
            </Link>
          </li>
          <li>
            <Link to="/patient" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/patient')}`}>
              <i class="fa-solid fa-hospital-user smb-icon"></i>
              <span className="menu-title">Patients</span>
            </Link>
          </li>
          <li >
            <Link to="/location" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/location')}`}>
              <i className="fa-solid fa-location-crosshairs fa-fw"></i>
              <span className="menu-title">Locations</span>
            </Link>
          </li>
          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#samplecollection" aria-expanded="false" id='samplemaster' aria-controls='settings'>
              <i className="fa-solid fa-vial smb-icon"></i>
              <span className="menu-title">Sample Master</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="samplecollection">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><Link to="/samplemaster/samplecollection" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/samplemaster/samplecollection')}`}>
                  <i className="fa-solid fa-flask smb-icon"></i>
                  <span className="menu-title">Sample Collection</span>
                </Link></li>
                <li><Link to="/samplemaster/sampleconfirmation" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/samplemaster/sampleconfirmation')}`}>
                  <i className="fa-solid fa-vial-circle-check smb-icon"></i>
                  <span className="menu-title">Sample Confirmation</span>
                </Link></li>
                <li><Link to="/samplemaster/landingscreen" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/samplemaster/landingscreen')}`}>
                  <i className="fa-solid fa-vials smb-icon"></i>
                  <span className="menu-title">Landing Screen</span>
                </Link></li>
                <li><Link to="/samplemaster/updatestatus" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/samplemaster/updatestatus')}`}>
                  <i className="fa-solid fa-vial-circle-check smb-icon"></i>
                  <span className="menu-title">Manage Inventory</span>
                </Link></li>

              </ul>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#inventorymanagement" aria-expanded="false" aria-controls='inventorymanagement' id='inventorymanagementlink'>
              <i class="fa-solid fa-warehouse"></i>
              <span className="menu-title">Inventory Management</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="inventorymanagement">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li id='toggleembryobtn'><Link to="/inventorymanagement/embryo" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/inventorymanagement/embryo')}`}>
                  <i className="fa-solid fa-glass-water smb-icon"></i>
                  <span className="menu-title">Embryo</span>
                </Link></li>
                <li id='toggleooctyebtn'><Link to="/inventorymanagement/ooctye" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/inventorymanagement/ooctye')}`}>
                  <i className="fa-solid fa-fill-drip smb-icon"></i>
                  <span className="menu-title">Ooctye</span>
                </Link></li>
                <li id='togglespermbtn'><Link to="/inventorymanagement/sperm" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/inventorymanagement/sperm')}`}>
                  <i class="fa-solid fa-prescription-bottle-medical"></i>
                  <span className="menu-title">Sperm</span>
                </Link></li>
              </ul>
            </div>
          </li>

          <li>
            <Link to="/longtermfacility" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/longtermfacility')}`}>
              <i className="fa-solid fa-hospital smb-icon"></i>
              <span className="menu-title">Long-Term Facility</span>
            </Link>
          </li>

          <li>
            <Link to="/manageshipment" type="button" className={`nav-link sidebar-menu-item dc-submenu-link ${isActive('/manageshipment')}`}>
              <i className="fa-solid fa-truck-medical smb-icon"></i>
              <span className="menu-title">Manage Shipment</span>
            </Link>
          </li>

          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#settings" aria-expanded="false" aria-controls='settings' id='settingsLink'>
              <i className="fa-solid fa-gear smb-icon"></i>
              <span className="menu-title">Settings</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="settings">
              
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><Link to="/settings/user" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/user')}`}>
                  <i className="fa-solid fa-user-tie smb-icon"></i>
                  <span className="menu-title">Admin Users</span>
                </Link></li>
                <li><Link to="/settings/cane" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/cane')}`}>
                  <i className="fa-solid fa-glass-water smb-icon"></i>
                  <span className="menu-title">Cane</span>
                </Link></li>
                <li><Link to="/settings/tank" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/tank')}`}>
                  <i className="fa-solid fa-fill-drip smb-icon"></i>
                  <span className="menu-title">Tank</span>
                </Link></li>
                <li><Link to="/settings/cannister" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/cannister')}`}>
                  <i class="fa-solid fa-prescription-bottle-medical"></i>
                  <span className="menu-title">Cannister</span>
                </Link></li>
                <li><Link to="/settings/roles" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/roles')}`}>
                  <i className="fa-solid fa-user-tag smb-icon"></i>
                  <span className="menu-title">Roles</span>
                </Link></li>
                <li><Link to="/settings/patientlanguage" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/patientlanguage')}`}>
                  <i class="fa-solid fa-language smb-icon"></i>
                  <span className="menu-title">Patient Language</span>
                </Link></li>
                <li><Link to="/settings/specimentypestatus" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/settings/specimentypestatus')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Specimen Type Status</span>
                </Link></li>

              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#embryosetup" aria-expanded="false" aria-controls='embryosetup' id='embryosetupLink'>
              <i className="fa-solid fa-screwdriver-wrench smb-icon"></i>
              <span className="menu-title">Embryo Setup</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="embryosetup" >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li ><Link to="/embryosetup/pgtresult" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/pgtresult')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">PGT Result</span>
                </Link></li>
                <li ><Link to="/embryosetup/eggsource" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/eggsource')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">EGG Source</span>
                </Link></li>
                <li ><Link to="/embryosetup/spermsource" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/spermsource')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Sperm Source</span>
                </Link></li>
                <li ><Link to="/embryosetup/freezemethod" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/freezemethod')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Freeze Method</span>
                </Link></li>
                <li ><Link to="/embryosetup/devicetype" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/devicetype')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Device Type</span>
                </Link></li>
                <li ><Link to="/embryosetup/pgtlab" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/pgtlab')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">PGT Lab</span>
                </Link></li>
                <li ><Link to="/embryosetup/cryotech" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/cryotech')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Cryo Tech</span>
                </Link></li>
                <li ><Link to="/embryosetup/bxtech" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/bxtech')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">BX Tech</span>
                </Link></li>
                <li ><Link to="/embryosetup/tubingtech" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/embryosetup/tubingtech')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Tubing Tech</span>
                </Link></li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#oocytesetup" aria-expanded="false" aria-controls='oocytesetup' id='oocytesetupLink'>
              <i className="fa-solid fa-screwdriver-wrench smb-icon"></i>
              <span className="menu-title">Oocyte Setup</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="oocytesetup" >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li ><Link to="/oocytesetup/oocytestage" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/oocytestage')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Oocyte Stage</span>
                </Link></li>
                <li ><Link to="/oocytesetup/oocyteday" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/oocyteday')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Oocyte Day</span>
                </Link></li>
                <li ><Link to="/oocytesetup/eggsource" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/eggsource')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Egg Source</span>
                </Link></li>
                <li ><Link to="/oocytesetup/freezemethod" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/freezemethod')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Freeze Method</span>
                </Link></li>
                <li ><Link to="/oocytesetup/devicetype" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/devicetype')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Device Type</span>
                </Link></li>
                <li ><Link to="/oocytesetup/canelabel" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/canelabel')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Cane Label</span>
                </Link></li>
                <li ><Link to="/oocytesetup/cryotech" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/oocytesetup/cryotech')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Cryo Tech</span>
                </Link></li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#spermsetup" aria-expanded="false" aria-controls='oocytesetup' id='spermsetupLink'>
              <i className="fa-solid fa-screwdriver-wrench smb-icon"></i>
              <span className="menu-title">Sperm Setup</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="spermsetup" >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li ><Link to="/spermsetup/sampletype" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/spermsetup/sampletype')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Sample Type</span>
                </Link></li>
                <li ><Link to="/spermsetup/spermsource" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/spermsetup/spermsource')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Sperm Source</span>
                </Link></li>
                <li ><Link to="/spermsetup/freezemethod" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/spermsetup/freezemethod')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Freeze Method</span>
                </Link></li>
                <li ><Link to="/spermsetup/vialtype" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/spermsetup/vialtype')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Vial Type</span>
                </Link></li>
                <li ><Link to="/spermsetup/cryotech" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/spermsetup/cryotech')}`}>
                  <i className="fa-solid fa-gear smb-icon"></i>
                  <span className="menu-title">Cryo Tech</span>
                </Link></li>
              </ul>
            </div>
          </li>

          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#configuration" aria-expanded="false" aria-controls='configuration' id='configurationLink'>
              <i className="fa-solid fa-screwdriver-wrench smb-icon"></i>
              <span className="menu-title">Configuration</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="configuration" >
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li ><Link to="/configuration/lookupmaster" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/configuration/lookupmaster')}`}>
                  <i className="fa-solid fa-table"></i>
                  <span className="menu-title">Lookup Master</span>
                </Link></li>
              </ul>
            </div>
          </li>



          <li className="nav-item">
            <a className="nav-link sidebar-menu-item collapsed has-submenu" type="button" data-bs-toggle="collapse"
              data-bs-target="#reportscollection" aria-expanded="false" id='reports' aria-controls='settings'>
              <i className="fa-solid fa-file-lines smb-icon"></i>
              <span className="menu-title">Reports</span>
            </a>
            <div className="collapse collapse sidebar-submenus dc-has-menu" id="reportscollection">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li><Link to="/reports/auditlogreport" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/reports/auditlogreport')}`}>
                  <i className="fa-solid fa-clock-rotate-left smb-icon"></i>
                  <span className="menu-title">Audit Log Report</span>
                </Link></li>
                <li><Link to="/reports/inventory" type="button" className={`nav-link sidebar-submenu-item dc-submenu-link ${isActive('/reports/inventory')}`}>
                  <i className="fa-solid fa-clock-rotate-left smb-icon"></i>
                  <span className="menu-title">Inventory</span>
                </Link></li>

              </ul>
            </div>
          </li>


        </ul>
      </div>
    </div>

  )
}

export default SideMenu